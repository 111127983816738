<!-- Hero Slider -->
<div class="hero-slider owl-carousel owl-theme">
    <div class="hero-slider-item item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span>Since 1992</span>
                        <h1>Transport & Logistic Services</h1>
                        <p>Victor carrier is a liquefied petroleum gas transport company.</p>
                        <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                        <a routerLink="/" class="default-btn-two">Get A Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hero-slider-item item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span>Since 1992</span>
                        <h1>Transport & Logistic<br> Services</h1>
                        <p>.Our company, Victor Carriers mainly deals with LPG transport via trucks. </p>
                        <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                        <a routerLink="/" class="default-btn-two">Get A Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hero-slider-item item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span>Since 1992</span>
                        <h1>Transport & Logistic<br> Services</h1>
                        <p>We are proud to offer the sale of Bulk LPG, Propane, and Butane on behalf of IPPL.</p>
                        <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                        <a routerLink="/" class="default-btn-two">Get A Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Hero Slider -->

<!-- Contact Area -->
<div class="container">
    <div class="contact-area mb-85">
        <div class="contact-content">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-phone'></i>
                        <h4>Contact Number</h4>
                        <p><a href="tel:+4560123987">+91 72 5015 3404</a></p>
                        <p><a href="tel:+0123456789">+91 79 7982 8714</a></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-location-plus'></i>
                        <h4>Our Location</h4>
                        <p>Bhilai Pahari , NH33 , </p>
                        <p>Jamshedpur ,</p>
                        <p>Jharkhand 831012</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bx-show'></i>
                        <h4>Opening Hours</h4>
                        <p>9:00 AM - 6:00 PM</p>
                        <p>Monday-Saturday</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-envelope'></i>
                        <h4>Our Email</h4>
                        <p><a href="mailto:admin@thevictorgroup.in">admin@thevictorgroup.in</a></p>
                        <p><a href="mailto:info@thevictorgroup.in">info@thevictorgroup.in</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Contact Area -->

<!-- About Area --> 
<div class="about-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="about-contant">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>Make Transportation More Efficient</h2>
                    </div>
                    <div class="about-text">
                        <p style="text-align: justify;">Victor Carrier is a logictic firm specializing in bulk LPG transport. The Company was started under the leadership of Vishnu Malhotra and Shiv Malhotra in industrial hub of country , Jamshedpur.</p>
                        <p style="text-align: justify;">The company was started in 1994 with a small fleet size of five trucks. It has now grown its fleet size to approximately five hundred trucks. We are currently servicing renowned companies like Indian Oil Corporation Ltd., Bharat Petroleum Corporation Ltd., Hindustan Petroleum Corporation Ltd and many others. .</p>
                        <a routerLink="/about" class="default-btn-one btn-bs">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about.jpg" alt="about-image">
                </div>
            </div>

            <div class="col-lg-3 col-md-12">
                <div class="about-tabs">
                    <div class="tab-contant">
                        <h2 class="title">Our Core Value</h2>
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-link active" id="nav-vision-tab" data-bs-toggle="tab" href="#nav-vision" role="tab" aria-controls="nav-vision" aria-selected="true">Vision</a>
                                <a class="nav-link nav-link-two" id="nav-mission-tab" data-bs-toggle="tab" href="#nav-mission" role="tab" aria-controls="nav-mission" aria-selected="false">Mission</a>
                                <a class="nav-link nav-link-two" id="nav-value-tab" data-bs-toggle="tab" href="#nav-value" role="tab" aria-controls="nav-value" aria-selected="false">Value</a>
                            </div>
                        </nav>

                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-vision" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li style="text-align: justify;"><i class='bx bx-check'></i>Victor Carrier aims at delivering highest quality services to our customers to support business logistics and to achieve complete customer satisfaction through innovation and refinement of service levels.</li>
                                        <li style="text-align: justify;"><i class='bx bx-check'></i>Victor Carrier also focuses on improving the business process and to ensure that utmost care is put in delivery of services to meet the customer satisfaction.</li>
                                        <!--<li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                                        <li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>-->
                                    </ul>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-mission" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li style="text-align: justify;"><i class='bx bx-check'></i> Victor Carrier pledges to provide personal attention by providing the best standards of practice, reliable performance and value added services to every customer.</li>
                                        <li style="text-align: justify;"><i class='bx bx-check'></i> To provide a consistent level of excellent service that will earn a reward consistent with our investment, risk and results.</li>
                                        <li style="text-align: justify;"><i class='bx bx-check'></i> To develop a partnership, founded on trust and respect that has been earned through consistent delivery of quality services.</li>
                                        <!--<li><i class='bx bx-check'></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>-->
                                    </ul>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-value" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li><i class='bx bx-check'></i> RESPECT FOR THE CO-WORKERS AND CUSTOMERS.</li>
                                        <li><i class='bx bx-check'></i> HARDWORK AND MODESTY.</li>
                                        <li><i class='bx bx-check'></i> CONTRIBUTION TOWARDS COMMUNITY AND ENVIROMENT SUSTAINIBILITY.</li>
                                        <li><i class='bx bx-check'></i> COMMITMENT TO QUALITY SERVICE.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->  

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>We are providing below services</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bxs-ship'></i>
                    <h3>LPG Transportation</h3>
                    <p>Bulk LPG, Propane and Butane Transportation.</p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bx-store'></i>
                    <h3>Dealership</h3>
                    <p>Dealer of Micheline and CEAT Tyres.</p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bxs-truck'></i>
                    <h3>Selling Bulk LPG</h3>
                    <p>Selling Bulk LPG,Propane and Butane on behalf of IPPL's.</p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bx-transfer'></i>
                    <h3>B2B Services</h3>
                    <p>Transport Trailers Maintenance Services.</p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Choose Area -->
<!--
<div class="choose-area ptb-100">
    <div class="shape-one"><img src="assets/img/shape/shape5.png" alt="Shape"></div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-world'></i>
                        <h3>Worldwide Service</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  magna aliqu.</p>
                    </div>
                </div>

                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bxs-paper-plane'></i>
                        <h3>Tracking Service</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  magna aliqu.</p>
                    </div>
                </div>

                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bxs-truck'></i>
                        <h3>Fast and Reliable</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  magna aliqu.</p>
                    </div>
                </div>

                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-support'></i>
                        <h3>24/7 Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  magna aliqu.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="choose-text">
                    <div class="section-title">
                        <span>Why Choose Us</span>
                        <h2>Safe and Faster Logistic Service Neer You</h2>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <a routerLink="/contact" class="default-btn-one">Contact Us</a>
                    <div class="shape-image"><img src="assets/img/shape/shape2.png" alt="icon"></div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Choose Area -->

<!-- Speciality Slider Area -->
<!--
<div class="speciality-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Speciality</span>
            <h2>Safe and Faster Logistic Services</h2>
        </div>

        <div class="speciality-slider owl-carousel owl-theme">
            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality1.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">Office Relocation</a></h3>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.</p>
                </div>
            </div>

            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality2.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">House Moving</a></h3>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.</p>
                </div>
            </div>

            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality3.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">Curier Worldwide</a></h3>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.</p>
                </div>
            </div>

            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality1.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">Office Relocation</a></h3>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.</p>
                </div>
            </div>

            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality2.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">House Moving</a></h3>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.</p>
                </div>
            </div>

            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/speciality3.jpg" alt="image"></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">Curier Worldwide</a></h3>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete.</p>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Speciality Slider Area -->

<!-- Testimonials Area -->
<!--
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="shape-two">
            <img src="assets/img/shape/shape6.png" class="shape-1" alt="shape">
            <img src="assets/img/shape/shape3.png" alt="shape">
        </div>

        <div class="section-title">
            <span>Feedback</span>
            <h2>Whats Our Clients Said About Us</h2>
        </div>

        <div class="testimonials-slider owl-carousel owl-theme">
            <div class="testimonials-card">
                <div class="client-img">
                    <img src="assets/img/clients/client6.jpg" alt="image">
                    <h3>Jhon Smith</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div> 
                <div class="feedback-text">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
            </div>

            <div class="testimonials-card">
                <div class="client-img">
                    <img src="assets/img/clients/client5.jpg" alt="image">
                    <h3>William leo</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div> 
                <div class="feedback-text">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
            </div>

            <div class="testimonials-card">
                <div class="client-img">
                    <img src="assets/img/clients/client4.jpg" alt="image">
                    <h3>Benjamin Liam</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div> 
                <div class="feedback-text">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Testimonials Area -->

<!-- Partner Area -->
<!--
<div class="partner-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Partner</span>
            <h2>20+ Companies Trusted us and Getting Result</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner2.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner3.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner4.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner5.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner6.png" alt="logo"></a>
            </div>
        </div>
    </div>
</div>-->
<!-- End Partner Area -->

<!-- Transportation Area -->
<!--
<div class="transportation-area">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-lg-6 col-md-12">
                <div class="transportation-form ptb-100">
                    <div class="section-title">
                        <span>Transportation</span>
                        <h2>Take Your Goods Anywhere</h2>
                    </div>

                    <div class="transportation-shape">
                        <img src="assets/img/shape/shape3.png" alt="icon">
                    </div>

                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Height (CM):">
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Weight (KG):">
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Width (CM):">
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Type of commodity:">
                        </div>

                        <div class="form-group mb-30">
                            <input type="text" class="form-control" placeholder="Distance (KM):">
                        </div>

                        <div class="form-btn">
                            <button type="submit" class="default-btn-one mr-4">Cost Calculate</button>
                            <button type="submit" class="default-btn-two">Get A Full Quote</button>
                        </div>

                        <h3>Get a brochure / 10% Discount on first order</h3>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="popup-video video-bg">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="video-btn">
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="popup-youtube">
                                    <i class="bx bx-play whiteText"></i>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Transportation Area -->