<!-- Footer Area-->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="logo">
                        <img src="assets/img/logo.jpg" alt="logo">
                    </div>
                    <p>Victor Carriers mainly deals with LPG transport via trucks.</p>
                    <ul class="footer-socials">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>Company</h3>
            
                    <ul class="footer-text">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <!--<li><a routerLink="/">Features</a></li>
                        <li><a routerLink="/">Cost calculator</a></li>
                        <li><a routerLink="/">Latest News</a></li>-->
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-50">
                    <h3>Support</h3>
            
                    <ul class="footer-text">
                        <li><a routerLink="/faq">FAQ’s</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/">Community</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="info-list">
                        <li><i class='bx bxs-location-plus'></i> Bhilai Pahari,NH33, Jamshedpur, Jharkhand 831012</li>
                        <li><i class='bx bxs-envelope'></i> <a href="mailto:admin@thevictorgroup.in">admin@thevictorgroup.in</a></li>
                        <li><i class='bx bxs-envelope'></i> <a href="mailto:info@thevictorgroup.in">info@thevictorgroup.in</a></li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:+917250153404">+917250153404,+917979828714</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Footer bottom Area -->
<div class="footer-bottom">
    <div class="container">
        <p>© Victor group is Proudly Owned by <a href="https://thevictorgroup.in/" target="_blank">Victor carrier</a></p>
    </div>
</div>
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top -->