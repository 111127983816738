<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Our Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Our Services Area -->
<div class="our-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>Safe, Faster and Relaible Logistics Services</h2>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services1.jpg" alt="image">
                    <div class="service-caption">
                        <h3>LPG Transportation</h3>
                        <p>Bulk LPG, Propane and Butane Transportation.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services2.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Dealership</h3>
                        <p>We are the Dealer of Micheline and CEAT Tyres.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services3.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Selling Bulk LPG</h3>
                        <p>Selling Bulk LPG,Propane and Butane on behalf of IPPL's.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <!--<div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services4.jpg" alt="image">
                    <div class="service-caption">
                        <h3>B2B Exchange</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services5.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Home Shipping</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services6.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Office Moving</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-12 col-md-12">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" routerLink="/services"><span aria-hidden="true">&laquo;</span></a></li>
                    <li class="page-item active"><a class="page-link" routerLink="/services">1</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">2</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">3</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services"><span aria-hidden="true">&raquo;</span></a></li>
                </ul>
            </div>-->
        </div>
    </div>
</div>
<!-- Our End Services Area -->

<!-- Best Logistic Area -->
<div class="best-logistic-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h2>Best <span>Logistic Service</span> Solution Near you</h2>
                    <p style="text-align: justify;">We provide in-house commercial and technical management services for all of the tankers and horse in our fleet. Our mission is to arrange safe, reliable and trouble free transportation, and we are committed to the highest quality of customer service.
                    </p>
                </div>
                <a routerLink="/about" class="default-btn-one">Read More</a>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="assets/img/services/services7.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Best Logistic Area -->

<!-- Newsletter Area -->
<!--<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Newsletter Area -->