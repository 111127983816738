<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- About Safe Area -->
<div class="about-text-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-safe-text">
                    <h2>About Victor Carrier</h2>
                    <p style="text-align:justify">Victor Carrier is a logistics firm specializing in bulk LPG transport. The company was started under the leadership of Vishnu Malhotra and Shiv Malhotra in industrial hub of the country, Jamshedpur. </p>
                    <p style="text-align:justify">The company was started in 1994 with a small fleet size of five trucks. It has now grown its fleet size to approximately five hundred trucks. We are currently servicing renowned companies like Indian Oil Corporation Ltd., Bharat Petroleum Corporation Ltd., Hindustan Petroleum Corporation Ltd and many others.  </p>
                    <p style="text-align:justify">We provide in-house commercial and technical management services for all of the tankers and horse in our fleet. Our mission is to arrange safe, reliable and trouble free transportation, and we are committed to the highest quality of customer service.  </p>
                    <p style="text-align:justify">We are currently operating in six states- Jharkhand, Bihar, Orissa, Assam, Andra Pradesh and West Bengal </p>
   
                </div>

                <!--<div class="shipping-card">
                    <div class="shipping-contant">
                        <div class="shipping-sign">
                            <img src="assets/img/sign.png" alt="image">
                        </div>

                        <div class="shipping-image">
                            <img src="assets/img/clients/client1.png" alt="image">
                        </div>

                        <h3>John Doe</h3>
                        <span>CEO, Floza</span>
                        <p>Nor again is there anyone who loves or pursues or desires to.</p>
                    </div>
                </div>-->
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="safe-image">
                    <img src="assets/img/speciality/speciality2.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Safe Area -->

<!-- About Info -->
<!--<div class="about-info-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="about-info-card">
                    <h3>Our Mission</h3>

                    <ul>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="about-info-card">
                    <h3>Our Promises</h3>
                    
                    <ul>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="about-info-card">
                    <h3>Our Vission</h3>
                    
                    <ul>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        <li><i class='bx bx-check'></i> We provide  best logistic service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End About Info -->

<!-- Digital Area -->
<div class="digital-area ptb-100">
    <div class="container">
        <div class="digital-top-contant">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="digital-image">
                        <img src="assets/img/blog/blog7.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-text">
                        <h2>Trusted LPG Transportation From <span>1994</span></h2>
                        <p>We provide in-house commercial and technical management services for all of the tankers and horse in our fleet. Our mission is to arrange safe, reliable and trouble free transportation, and we are committed to the highest quality of customer service.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="digital-card-contant">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bx-cart-alt'></i>
                            <h3><span>1994</span> - Company Started</h3>
                            <p>The company was started in 1994 with a small fleet size of five trucks.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bx-map-alt'></i>
                            <h3><span>2014</span> - Office Started in Six States</h3>
                            <p>Started operating in six states- Jharkhand, Bihar, Orissa, Assam, Andra Pradesh and West Bengal.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bxs-truck' ></i>
                            <h3><span>2008</span> - Vehicles Adding</h3>
                            <p>. It has now grown its fleet size to approximately five hundred trucks.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bx-award'></i>
                            <h3><span>2023</span> - our Achivement</h3>
                            <p>Indian Oil Corporation Ltd., Bharat Petroleum Corporation Ltd., Hindustan Petroleum Corporation Ltd and many others are our clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Digital Area -->

<!-- partner Slider Area -->
<!--<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Partner</span>
            <h2>20+ Companies Trusted Us and Getting Result</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner1.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner2.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner3.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner4.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner5.png" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="#" target="_blank"><img src="assets/img/partner/partner6.png" alt="logo"></a>
            </div>
        </div>
    </div>
</div>-->
<!-- End partner Slider Area -->

<!-- Newsletter Area -->
<!--<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="newsletter-form">
                        <form>
                            <input type="email" class="form-control" placeholder="Email Address" required autocomplete="off">
                            <button type="submit" class="btn btn-primary">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Newsletter Area -->